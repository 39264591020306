

































































import Component, {mixins} from 'vue-class-component';
import dataFormat from '@/shared/utils/dataFormat';
import { EventBus } from '@/config/config';
import { Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { placeModule, reservationModule, ticketModule } from '@/store';
import { filter } from 'lodash';

@Component<Product>({
  components: {
    'thumbnail-list': () => import('@/components/common/ThumbnailList.vue'),
    'main-info': () => import('@/components/product/MainInfo.vue'),
    'product-description': () => import('@/components/product/ProductDescription.vue'),
    'product-config': () => import('@/components/product/ProductConfig.vue'),
    'reservation-info': () => import('@/components/product/ReservationInfo.vue'),
    'reservation-notice': () => import('@/components/product/ReservationNotice.vue'),
    'place-info': () => import('@/components/common/PlaceInfo.vue'),
    'place-feature': () => import('@/components/common/PlaceFeature.vue'),
    'to-reserve': () => import('@/components/common/ToReserve.vue'),
    'thumbnail-detail-modal': () => import('@/pages/modal/ThumbnailDetailModal.vue'),
    'thumbnail-list-modal': () => import('@/pages/modal/ThumbnailListModal.vue'),
    'place-map-modal': () => import('@/pages/modal/PlaceMapModal.vue'),
    'to-reserve-modal': () => import('@/pages/modal/ToReserveModal.vue'),
    'reservation-modal': () => import('@/pages/modal/ReservationModal.vue')
  },
  metaInfo() {
    return {
      title: this.place.english_name + ' | POING',
      meta: [
        {
          name: 'keywords',
          vmid: 'keywords',
          content: '포잉, POING, ' + this.product.title
        },
        {
          name: 'description',
          vmid: 'description',
          content: this.product.description
        },
        {
          name: 'url',
          property: 'og:url',
          vmid: 'og:url',
          content: window.location.href
        },
        {
          name: 'title',
          property: 'og:title',
          vmid: 'og:title',
          content: this.product.title + ' | POING'
        },
        {
          name: 'description',
          property: 'og:description',
          vmid: 'og:description',
          content: this.product.description
        },
        {
          name: 'image',
          property: 'og:image',
          vmid: 'og:image',
          content: this.product.image && this.product.image.original.url
        }
      ]
    }
  }
})
export default class Product extends mixins(dataFormat) {
  @Prop()
  public productId!: any;
  @Prop()
  public selectLang!: any;

  public isScroll: boolean = false;
  public realReservationDate: any = '';
  // public params: any = {
  //   id: this.productId,
  //   date: moment().add('1', 'days').format('YYYY-MM-DD'),
  //   person: 2
  // };
  public params: any = {
    product_id: this.productId,
    reservation_date: moment().add('1', 'days').format('YYYY-MM-DD'),
    person: 2,
    except_person: 0
  };
  public visitedList: any = [];
  public loadingStatus: boolean = false;
  public cartData: any = {};
  public timeSlot: any = [];
  public isReserveModal: boolean = false;

  public beforeRouteEnter(to, from, next) {
    // if (from.params.cartId) {
    //   reservationModule.deleteCart(from.params.cartId);
    // }
    ticketModule.fetchProductImage(to.params.productId);
    // ticketModule.fetchProductQna(to.params.productId);
    ticketModule.fetchProduct(to.params.productId).then((res) => {
      next((vm) => {
        vm.params.product_id = res.data.id;
        vm.loadingStart();
        vm.eventViewItem(res.data);
        placeModule.fetchOtherProduct({
          placeId: res.data.place_id,
          productId: to.params.productId
        });
        placeModule.fetchPlaceMenu(res.data.place_id);
        if (to.params.reserveDate) vm.params.product_id = to.params.reserveDate;
        else if (to.params.schedule_date && !to.params.reserveDate) vm.params.reservation_date = to.params.schedule_date;
        // reservationModule.fetchProductReservationTime(vm.params).then((res) => {
        //   // vm.realReservationDate = res.data.reservation_date;
        // });
        ticketModule.fetchAbleReservationDate(vm.params)
        .then(() => {
          if (vm.ableReservationDate && vm.ableReservationDate.capacity) {
            if (vm.$route.name === 'product') {
              const size2Time: any = vm.ableReservationDate.capacity.filter((row: any) => {
                return row.size === 2;
              })
              if (size2Time) {
                return vm.timeSlot = size2Time[0];
              }
              return null;
            } else {
              return vm.timeSlot = reservationModule.placeTime;
            }
          }
        });
        vm.visitedCookies();
        vm.loadingEnd();
        vm.$modal.hide('placeMap');
      });
    });
    EventBus.$emit('updateReserveTime', to.params.reserveTime);
    EventBus.$emit('updateTimeCheck', to.params.timeCheck);
  }

  public beforeRouteUpdate(to, from, next) {
    reservationModule.resetSchedule();
    ticketModule.fetchProduct(to.params.productId).then((res) => {
      placeModule.fetchOtherProduct({
        placeId: res.data.place_id,
        productId: to.params.productId
      });
      ticketModule.fetchProductImage(to.params.productId);
      this.params.product_id = res.data.id;
      reservationModule.fetchProductReservationTime(this.params).then((res) => {
        // this.realReservationDate = res.data.reservation_date;
      });
      ticketModule.fetchAbleReservationDate(this.params);
    });
    next();
  }

  public beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      reservationModule.resetSchedule();
    }, 1000);
    // reservationModule.resetSchedule();
    next();
  }

  public reservationDateUpdate(date: any) {
    this.params.reservation_date = date;
    reservationModule.fetchProductReservationTime(this.params);
  }
  public reservationDateUpdate2(data: any) {
    this.params.reservation_date = moment(data.date).format('YYYY-MM-DD');;
    this.params.except_person = data.except_person;
    reservationModule.fetchProductReservationTime(this.params);
  }
  public personUpdate(person: any) {
    this.params.person = person;
  }

  public refresh() {
    ticketModule.fetchProduct(this.$route.params.productId);
  }

  public beforeDestroy() {
    this.loadingEnd();
    // document.addEventListener('scroll', this.handleScroll);
  }
  public loadingStart() {
    EventBus.$emit('loadingStatus', true);
    this.loadingStatus = true;
  }
  public loadingEnd() {
    EventBus.$emit('loadingStatus', false);
    this.loadingStatus = false;
  }

  public eventViewItem(data) {
    const eventName = 'view_item';
    const eventParams: any = {
      type: 'product',
      id: data.id.toString(),
      name: data.title
    }
    // firebase.analytics().logEvent(eventName, eventParams);
  }

  // public mounted() {
  //   ticketModule.fetchProduct(this.productId).then(() => {
  //     placeModule.fetchPlaceProduct(this.product.place_id);
  //     placeModule.fetchPlaceMenu(this.product.place_id);
  //   });
  //   ticketModule.fetchProductImage(this.productId);
  //   // ticketModule.fetchProductQna(this.productId);
  //   reservationModule.fetchProductTime(this.params).then((res) => {
  //     this.realReservationDate = res.data.reservation_date
  //   });
  // }

  public handleScroll() {
    const el: any = document.querySelector('.product-detail-wrap') as HTMLDivElement;
    if (el) {
      const bottom = el.scrollHeight;
      let barHeight = (window.innerWidth * 0.75) - 53;
      if (window.innerWidth > 600) {
        barHeight = 500;
      }
      if (window.scrollY > barHeight) {
        if (window.scrollY > (bottom - window.innerHeight - 1)) {
          el.classList.add('scroll-bottom');
        } else {
          el.classList.remove('scroll-bottom');
        }
        return this.isScroll = true;
      }
      return this.isScroll = false;
    }
  }
  public created() {
    // document.addEventListener('scroll', this.handleScroll);
    // this.initReview(this.product.place_id);
  }

  public visitedCookies() {
    const now = new Date().getTime();
    const after1Month = now + 86400000 * 30;
    if (this.$cookies.get('poingVisited')) {
      this.visitedList = JSON.parse(this.$cookies.get('poingVisited'));
      this.visitedList = filter(this.visitedList, (i) =>
        i.place_id !== this.product.place.id && i.visited_at > now
      )
    }
    this.visitedList.unshift({
      place_id: this.product.place.id,
      visited_at: after1Month
    });
    const visited = JSON.stringify(this.visitedList);
    this.$cookies.set('poingVisited', visited);
  }

  public back() {
    if (window.history.length === 1) {
      this.$router.push({path: '/'});
    } else {
      this.$router.go(-1);
    }
  }

  // public initReview(id) {
  //   const params = {
  //     type: 'realtime',
  //     offset: 1,
  //     limit: 3,
  //     place_id: id,
  //     time: moment().format('YYYY-MM-DD HH:mm:ss')
  //   }
  //   reviewModule.fetchUserReview(params);
  // }

  public reserveModalUpdate(data: any) {
    if (data) {
      this.isReserveModal = true;
    } else {
      this.isReserveModal = false;
    }
  }

  public cartInfoUpdate(data: any) {
    this.cartData = data;
  }
  @Watch('reservationTimeData')
  public reservationTimeDataUpdate() {
    if (this.reservationTimeData && this.reservationTimeData.capacity) {
      const re: any = this.reservationTimeData.capacity.filter((row: any) => {
        return this.params.person === row.size;
      });
      if (re.length) {
        this.timeSlot = re[0];
      }
    }
  }
  @Watch('params.person')
  public paramsPersonUpdate() {
    if (this.reservationTimeData && this.reservationTimeData.capacity) {
      const re: any = this.reservationTimeData.capacity.filter((row: any) => {
        return this.params.person === row.size;
      });
      if (re.length) {
        this.timeSlot = re[0];
      }
    }
  }

  get product(): any {
    return ticketModule.productInfo;
  }
  get place() {
    return ticketModule.placeInfo;
  }
  get productImage() {
    return ticketModule.productImage;
  }
  get otherProduct() {
    return placeModule.otherProduct;
  }
  get placeMenu() {
    return placeModule.placeMenu;
  }
  get productTime() {
    return reservationModule.productReservationTime;
  }
  get reservationTimeData() {
    return reservationModule.productReservationTime;
  }
  get ableReservationDate() {
    return ticketModule.ableReservationDate;
  }
  // get timeSlot() {
  //   if (this.$route.name === 'product') {
  //     if (this.ableReservationDate && this.ableReservationDate.capacity) {
  //       const size2Time: any = this.ableReservationDate.capacity.filter((row: any) => {
  //         return row.size === 2;
  //       })
  //       if (size2Time[0]) {
  //         return size2Time[0].times;
  //       } else if (
  //         this.ableReservationDate.capacity &&
  //         this.ableReservationDate.capacity[0] &&
  //         this.ableReservationDate.capacity[0].times
  //       ) {
  //         let re = this.ableReservationDate.capacity[0].times;
  //         re.filter((row: any) => {
  //           return row.is_active = false;
  //         })
  //         return re;
  //       }
  //       return null;
  //     }
  //   } else {
  //     return reservationModule.placeTime;
  //   }
  // }
}
